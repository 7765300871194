.tabsContainer {
  // justify-content: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  font-size: 20px;
  transition: all 0.2s;
  margin: 50px 0;
  @media (max-width: 576px) {
    justify-content: space-between;
  }
  .tab {
    // font-family: "kalatexa";
    font-weight: bold;
    display: flex;
    width: 158px;
    justify-content: center;
    @media (max-width: 576px) {
      width: 100px;
    }
    @media (max-width: 320px) {
      width: 80px;
    }
    // @media (max-width: 414px) {
    //   flex: 0 0 50%;
    //   max-width: 50%;
    // }
    .content {
      display: flex;
      align-items: center;
      flex-direction: column;

      .image {
        width: 70px;
        height: 70px;
        @media (max-width: 576px) {
          width: 70px;
          height: 70px;
        }
      }
      .labelContainer {
        min-height: 110px;
        vertical-align: middle;
        display: flex;
        align-items: start;
        @media (max-width: 576px) {
          border-bottom: 0;
          min-height: 0;
        }
        &.active {
          .tabLabel {
            color: #ed208e;
            border-bottom: 2px solid #ed208e;
            @media (max-width: 991px) {
              border-bottom: 0;
            }
          }
        }
        .tabLabel {
          text-align: center;
          color: #000000;
          font-size: 18px;
          margin-top: 18px;
          @media (max-width: 576px) {
            font-size: 14px;
            margin-top: 5px;
          }
        }
      }
    }
  }
  .tabDisable {
    // font-family: "kalatexa";
    font-weight: bold;
    display: none;
    width: 150px;
    justify-content: center;
    @media (max-width: 576px) {
      display: flex;
      width: 115px;
    }
    @media (max-width: 320px) {
      display: flex;
      width: 95px;
    }
  }
}

.foursteps {
  padding: 40px 0 70px 0;
  border-top: 1px solid #dddee0;
  .title {
    color: #e6007e;
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 33px;
    @media (max-width: 576px) {
      font-size: 24px;
      margin-bottom: 20px;
    }
  }
  .step {
    display: flex;
    @media (max-width: 991px) {
      margin-bottom: 20px;
    }
    img {
      width: 60px;
      height: 60px;
      margin-right: 10px;
    }
    p {
      margin-bottom: 0;
      color: rgba(0, 0, 0, 0.6);
      font-size: 18px;
      font-weight: 600;
      &:nth-child(1) {
        font-weight: bold;
        color: #353535;
      }
    }
  }
}

:root {
  /* --mainColor: #218838; */
  --mainColor: #E6007E;
  --subColor: #888888;
  --fuchsia: #E6007E;
  --white: #FFFFFF;
  --detailColor: #008ECF;
  --borderRadiusAnsBtnNAndAnsBtnY: 5px;
  --progressStepActiveColor: #E6007E;
  --progressStepCompletedColor: #E6007E;
  --stepButtonColor: #E6007E;
}

@font-face {
  font-family: customFont;
  src: url("./fonts/KaLaTeXaTEXT_Regular.ttf");
} 

@font-face {
  font-family: customFont;
  src: url("./fonts/KaLaTeXaTEXT_Bold.ttf");
  font-weight: bold;
}

@font-face {
  font-family: customFont;
  src: url("./fonts/KaLaTeXaTEXT_Regular.ttf");
  font-style: italic;
}

@font-face {
  font-family: customFont;
  src: url("./fonts/KaLaTeXaTEXT_Bold.ttf");
  font-weight: bold;
  font-style: italic;
}

body, html {
  font-size: 1.03rem;
  overflow: hidden !important;
  margin: 0;
  font-family: customFont !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.MuiFormLabel-root, .MuiInputBase-input, .MuiInputBase-root, .MuiTypography-body1, .MuiTypography-body2, .MuiTypography-body3, .MuiMenuItem-root{
  font-family: customFont !important;
}

.PrivateSwitchBase-root-1, .PrivateSwitchBase-root-2, .PrivateSwitchBase-root-3{
  padding: 8px !important;
}

.MuiSelect-select:focus{
  background-color: transparent !important;
}

.text-placeholder{
  color: #999 !important;
}

.oppositeWhite{
  background: rgb(247, 248, 252);
  background-color: rgb(247, 248, 252);
}

.oppositeBlackBlue{
  background: rgb(33, 43, 54);
  background-color: rgb(33, 43, 54);
  color: rgb(255, 255, 255) !important;
}
.oppositeBlack{
  background: rgb(20, 20, 20);
  background-color: rgb(20, 20, 20);
  color: rgb(255, 255, 255) !important;
}

a:hover {
  color: var(--mainColor);
  text-decoration: none; 
}
.oppositeBlack a[href]{
  color: rgb(255, 255, 255) !important;
  text-decoration: none; 
}


.wordWarp{
  word-wrap:break-word !important;
}

.heirBoxShadow{
  border: unset !important;
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.3) !important;
  -moz-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.3) !important;
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.3) !important;
}

.setNumber { 
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0; 
}

.csMainColor{
  color: var(--mainColor) !important;
}

.csSubColor{
  color: var(--subColor) !important;
}

.csDetailColor{
  color: var(--detailColor) !important;
}

.csMainBG{
  background-color: var(--mainColor) !important;
}

.csMainColorHeaderBG{
  background-color: var(--mainHerderColor) !important;
}

.csSubBG{
  background-color: var(--subColor) !important;
}

.planBG{
  background-color: rgb(241, 241, 241);
}

.tableFix {
  position: sticky;
  left: 0;
  visibility:visible;
}

.table-striped tbody tr:nth-of-type(odd) .tableFix{
  background-color: rgb(234, 234, 235);
}

.table-striped tbody tr .tableFix{
  background-color: rgb(253, 253, 254);
}

.table-striped tfoot tr .tableFix{
  background-color: #888;
}

tfoot{
  background-color: #888;
}

.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

.borderBtn{
  border: 2px dashed var(--mainColor);
}

.mainBtn, .mainBtn:hover, .mainBtn:not(:disabled):not(.disabled):active{
  color: #fff;
  background-color: var(--mainColor);
  border-radius: 0px;
  border: 2px solid;
  border-color: var(--mainColor);
  width: 100%;
}

.mainBtn2, .mainBtn2:hover, .mainBtn2:not(:disabled):not(.disabled):active{
  color: #fff;
  background-color: var(--mainColor);
  border-radius: 0px;
  border: 2px solid;
  border-color: var(--mainColor);
}

.mainBtnRadius, .mainBtnRadius:hover, .mainBtnRadius:focus, .mainBtnRadius:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: var(--mainColor);
  border-radius: 20px;
  border: 2px solid;
  border-color: var(--mainColor);
  width: 300px;
  max-width: 100%;
  padding: .475rem .75rem;
}
.mainBtnRadius:disabled {
  color: #fff;
  background-color: gray;
  border-radius: 20px;
  border: 2px solid transparent;
  /* border-color: var(--mainColor); */
  width: 300px;
  max-width: 100%;
  padding: .475rem .75rem;
}

.mainBtnRadiusV2, .mainBtnRadiusV2:hover, .mainBtnRadiusV2:focus, .mainBtnRadiusV2:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: var(--detailColor);
  border-radius: 8px;
  border: 2px solid;
  border-color: var(--detailColor);
  width: 73px;
  max-width: 100%;
  padding: .1rem .1rem;
  margin-bottom: 5px;
  margin-left: 5px;
}
.mainBtnRadiusV2:disabled {
  color: #fff;
  background-color: gray;
  border-radius: 8px;
  border: 2px solid;
  /* border-color: var(--detailColor); */
  width: 73px;
  max-width: 100%;
  padding: .1rem .1rem;
  margin-bottom: 5px;
  margin-left: 5px;
}

.mainBtnRadiusV3, .mainBtnRadiusV3:hover, .mainBtnRadiusV3:focus, .mainBtnRadiusV3:not(:disabled):not(.disabled):active {
  color: var(--mainColor);
  background-color: white;
  border-radius: 30px;
  border: 2px solid;
  border-color: var(--mainColor);
  width: 400px;
  max-width: 100%;
  padding: .475rem .75rem;
  font-size: 24px;
}
.mainBtnRadiusV3:disabled {
  color: #fff;
  background-color: gray;
  border-radius: 30px;
  border: 2px solid;
  /* border-color: var(--mainColor); */
  width: 400px;
  max-width: 100%;
  padding: .475rem .75rem;
  font-size: 24px;
}
@media (max-width: 576px) {
  .mainBtnRadiusV3, .mainBtnRadiusV3:hover, .mainBtnRadiusV3:focus, .mainBtnRadiusV3:not(:disabled):not(.disabled):active {
    font-size: 18px;
  }
  .mainBtnRadiusV3:disabled {
    font-size: 18px;
  }
}

.subBtn, .subBtn:hover, .subBtn:not(:disabled):not(.disabled):active{
  color: var(--mainColor);
  background-color: white;
  border: 1.5px solid;
  border-color: var(--mainColor);
  border-radius: 8px;
  width: 100%;
}

.heirDelBtn, .heirDelBtn:hover, .heirDelBtn:not(:disabled):not(.disabled):active{
  color: red;
  background-color: white;
  border: 1.5px solid red;
  border-radius: 20px;
  font-size: 0.8rem;
  /* width: 100%; */
  position: absolute;
  right: 15px;
  padding-top: 0px;
  padding-bottom: 0px;
  top: 13px;
  padding-left: 2rem;
  padding-right: 2rem;
}

.editInfoBtn, .editInfoBtn:hover, .editInfoBtn:not(:disabled):not(.disabled):active{
  color: white;
  background-color: rgb(67, 143, 203);
  border: 1.5px solid rgb(67, 143, 203);
  border-radius: 20px;
  font-size: 0.8rem;
  /* width: 100%; */
  position: absolute;
  right: 15px;
  padding-top: 0px;
  padding-bottom: 0px;
  top: 13px;
  padding-left: 2rem;
  padding-right: 2rem;
}

.pSelectBtn{
  color: black;
  background-color: transparent;
  border: 1px solid rgba(0,0,0,0.2);
  border-radius: 5px;
  padding-top: 0.5rem;
  padding-bottom: 0.6rem;
}

.pSelectedBtn, .pSelectedBtn:hover{
  color: white;
  background-color: var(--mainColor);
  border: 1px solid var(--mainColor);
  border-radius: 5px;
  padding-top: 0.5rem;
  padding-bottom: 0.6rem;
}

.subBtnRadius, .subBtnRadius:hover, .subBtnRadius:not(:disabled):not(.disabled):active{
  color: var(--mainColor);
  background-color: transparent;
  border-radius: 20px;
  border: 2px solid;
  border-color: var(--mainColor);
  width: 300px;
  max-width: 100%;
  padding: .475rem .75rem;
}

.ansBtnCheck, .ansBtnCheck:hover, .ansBtnCheck:not(:disabled):not(.disabled):active{
  color: white;
  background-color: var(--mainColor);
  border-radius: var(--borderRadiusAnsBtnNAndAnsBtnY);
  border: 1px solid;
  border-color: var(--mainColor);
  width: 300px;
  max-width: 100%;
  padding: .475rem .75rem;
}
.ansBtnN, .ansBtnN:hover, .ansBtnN:not(:disabled):not(.disabled):active{
  color: var(--mainColor);
  background-color: transparent;
  border-radius: 5px;
  border: 1px solid;
  border-color: var(--mainColor);
  width: 300px;
  max-width: 100%;
  padding: .475rem .75rem;
}
.ansBtnY, .ansBtnY:hover, .ansBtnY:not(:disabled):not(.disabled):active{
  color: #888;
  background-color: transparent;
  border-radius: var(--borderRadiusAnsBtnNAndAnsBtnY);
  border: 1px solid;
  border-color: #888;
  width: 300px;
  max-width: 100%;
  padding: .475rem .75rem;
}

.payBtn, .payBtn:hover, .payBtn:not(:disabled):not(.disabled):active{
  color: #000;
  background-color: transparent;
  border-radius: 5px;
  border: 1px solid;
  border-color: rgba(0, 0, 0, 0.3);
  width: 300px;
  max-width: 100%;
  padding: .475rem .75rem;
}

.spanLink{
  cursor: pointer;
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
.spanLink:hover{
  color: #0056b3;
  text-decoration: underline;
}


.payActiveBtn, .payActiveBtn:hover, .payActiveBtn:not(:disabled):not(.disabled):active{
  color: white;
  background-color: var(--mainColor);
  border-radius: 5px;
  border: 1px solid;
  border-color: var(--mainColor);
  width: 300px;
  max-width: 100%;
  padding: .475rem .75rem;
}

.KPayPlusCard{
  border-radius: 5px;
  border-color: rgba(0, 0, 0, 0.3);
}

.KPayPlusCardActive{
  border-radius: 5px;
  border-color: var(--mainColor);
}

@keyframes example {
  from {display: none;}
  to {display: block;}
}

.mainLoading{
  background-color: rgba(0,0,0,0.8);
  top: 0;
  left: 0
}

.mainShadow{
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.mainBorder{
  border: solid 1px var(--mainColor);
  border-radius: 8px;
}

.custom-control-input.is-valid:checked~.custom-control-label::before, .was-validated .custom-control-input:valid:checked~.custom-control-label::before{
  border-color: var(--mainColor);
  background-color: var(--mainColor);
}

.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: var(--mainColor);
  background-color: var(--mainColor);
}

.custom-control-input.is-valid~.custom-control-label, .was-validated .custom-control-input:valid~.custom-control-label{
  color: unset;
}

.form-control.is-valid, .was-validated .form-control:valid{
  border-color: #ced4da ;
  background-image: unset ;
  padding-right: .75rem ;
}

.form-control.is-valid:focus, .was-validated .form-control:valid:focus{
  border-color: #80bdff ;
  box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25) ;
}

.was-validated .custom-control-input:valid~.custom-control-label::before{
  border-color: #ced4da ;
}

.custom-select.is-valid:focus, .was-validated .custom-select:valid:focus{
  border-color: #ced4da;
  box-shadow: unset;
}

.custom-select.is-valid, .was-validated .custom-select:valid{
  border-color: #ced4da;
  padding-right: 1.75rem;
  background: #fff url("./images/dropdown.svg") no-repeat right .75rem center/8px 10px;
}

.was-validated .form-check-input:valid~.form-check-label{
  color: black;
}

.custom-select.is-invalid, .was-validated .custom-select:invalid {
  border-color: #dc3545 !important;
  padding-right: 1.75rem;
  background: #fff url("./images/dropdown.svg") no-repeat right .75rem center/8px 10px;
}

.form-control.is-invalid, .was-validated .form-control:invalid{
  border-color: #dc3545 !important;
  background-image: url("./images/invalid.svg") !important;
}

/* START: custom load spinner */
.css-4sishp{
  background-color: var(--mainColor) !important;
}
.css-ovgsi0{
  background-color: var(--mainColor) !important;
}
.css-y7cj9i{
  background-color: var(--mainColor) !important;
}
/* END: custom load spinner */



/* Screen 1 : bright day */

.buttom-style{
  box-shadow : 1.5px 5px 10px grey;
}

.online-sales-select{
  border : 1.5px solid #E8E9E8;
  box-shadow : 1.5px 5px 10px grey;
  color : #a7a8a7;
  outline : 0px;
  text-align-last : center;
}

.validate-select{
  border : 1.5px solid red;
  box-shadow : 1.5px 5px 10px rgb(156, 9, 9);
  color : red;

}

.form-footer{
  font-style : italic ;
  color : #A1A1A1 ;
}

.hide{
  display: none !important;
}

#validate-message{
  display: block;
  color: red;
  font-size: 1rem;
}
/* End Screen 1 */

/* Screen 3 : Health questions */
.text-left{
  float: left;
}

.text-center{
  text-align: center;
}

.btn-os-danger{
  color: #fff;
  background-color : #dc3545;
  border-color : #dc3545;
  
  filter: opacity(0.5);
}

.btn-os-success{
  color: #fff;
  background-color : #28a745;
  border-color : #28a745;
  filter: opacity(0.5);
}

.btn-os-danger:focus, .btn-os-success:focus,
.btn-os-danger:hover, .btn-os-success:hover,
.btn-os-danger.active, .btn-os-success.active{
  filter: opacity(1);
  color: #fff;
  box-shadow : 2.5px 5px 5px grey !important;
}

.btn-os-short{
  width : 5rem;
}

.validate-panal{
  border : 1.5px solid #dc3545 !important;
  box-shadow : 1.5px 5px 10px rgb(156, 9, 9) !important;
}

.alert-title-danger{
  color: #dc3545;
}
/* End Screen 3 */

/* Screen 5 */
.imageInput{
    width: 35px;
    padding: 0 5px 0 0;
  }
.paymentConclusion{
  border: var(--mainColor) solid 1px;
  box-shadow: 4px 2px 3px 1px #8e8e8e8c;
  border-radius: 10px;
}

.payTypebtn, .payTypebtn:hover, .payTypebtn:not(:disabled):not(.disabled):active{
  color: black;
  background-color: white;
  border: 2px solid;
  border-color: var(--mainColor);
  border-radius: 8px;
}

.payTypebtnActive, .payTypebtnActive:hover, .payTypebtnActive:not(:disabled):not(.disabled):active{
  color: white;
  background-color: var(--mainColor);
  border: 2px solid;
  border-color: var(--mainColor);
  border-radius: 8px;
}


.subBtnpayment{
  color: black;
  background-color: white;
  border: 2px solid;
  border-color: var(--mainColor);
  border-radius: 0px;
  width: 25%;
}

.subBtnpayment:hover{
  color: black;
  background-color: white;
  border: 2px solid;
  border-color: var(--mainColor);
  border-radius: 0px;
  width: 25%;
}

.subBtnpayment:not(:disabled):not(.disabled):active{
  color: black;
  background-color: white;
  border: 2px solid;
  border-color: var(--mainColor);
  border-radius: 0px;
}

.mainBtnpayment{
  color: #fff;
  background-color: var(--mainColor);
  border-radius: 0px;
  border: 2px solid;
  border-color: var(--mainColor);
  width: 25%;
}

/* End Screen 5 */

/* MATERIAL CUSTOM */
.MuiFormLabel-root.Mui-focused{
  color: var(--mainColor) !important;
}

.MuiInput-underline:after{
  border-bottom-color: var(--mainColor) !important;
}

.MuiPickersToolbar-toolbar{
  background-color: var(--mainColor) !important;
}

.MuiPickersDay-daySelected{
  background-color: var(--mainColor) !important;
}

.MuiButton-textPrimary{
  color: var(--mainColor) !important;
}

.MuiTypography-colorPrimary{
  color: var(--mainColor) !important;
}

.productPlanList .splide__arrows .splide__arrow--prev{
  left: -0.5rem;
  border-radius: 25%;
  background-color: rgba(0,0,0,0.2);
}
.productPlanList .splide__arrows .splide__arrow--next{
  right: -0.5rem;
  border-radius: 25%;
  background-color: rgba(0,0,0,0.2);
}

.planPremiumList .splide__arrows .splide__arrow--prev{
  left: 0rem;
  top: 30%;
  background: transparent;
}
.planPremiumList .splide__arrows .splide__arrow--next{
  right: 0rem;
  top: 30%;
  background: transparent;
}

.sweetalert--timeout div {
  z-index: 6000 !important;
}
.text--muted {
  color: #383838 !important;
}

.title--text {
  line-height: 2;
  color: var(--fuchsia);
  font-size: larger;
  font-weight: bold;
}

.content--container {
  background-color: var(--white);
  border: 1px #E8E8E8 solid;
  padding: 1rem;
}

.content--container .title--label {
  border-left: 0.25rem var(--fuchsia) solid;
  color: var(--fuchsia);
  font-size: larger;
  font-weight: bold;
  line-height: 1;
  display: flex;
  align-items: center;
}

.fuchsia-btn, .fuchsia-btn:hover, .fuchsia-btn:focus, .fuchsia-btn:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: var(--mainColor);
  border-radius: 20px;
  border: 2px solid;
  width: 7rem;
  max-width: 100%;
  padding: 0.2rem .5rem;
}

.gary-btn, .gary-btn:hover {
  color: #fff;
  background-color: gray;
  border-radius: 20px;
  border: 2px solid;
  width: 7rem;
  max-width: 100%;
  padding: 0.2rem .5rem;
}

.custom-table .row:nth-child(odd) [class^="col"] {
  background-color: #F2F2F2;
  border: 1px solid var(--white);
  text-align: center;
}

.custom-table .row:nth-child(even) [class^="col"] {
  background-color: #FAFAFA;
  border: 1px solid var(--white);
  text-align: center;
}

.custom-table .title {
  color: #717171;
  line-height: 5;
}

.custom-table .body {
  line-height: 2;
}

.content--container .MuiPaginationItem-page.Mui-focusVisible,
.content--container .MuiPagination-ul .Mui-selected,
.content--container .MuiPaginationItem-page:hover,
.content--container .MuiPaginationItem-page.Mui-selected:hover{
  background-color: var(--fuchsia);
  color: var(--white);
}

.content--container button:focus {
  outline: none;
}
.button--dropLead {
  display: block;
  position: fixed;
  top: 73px;
  right: 20px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: transparent;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 4px;
  color:var(--mainColor) !important;;
  font-weight: bold;
}
.button--dropLead:focus {
  display: block;
  position: fixed;
  top: 73px;
  right: 20px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: transparent;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 4px;
  color:var(--mainColor) !important;
  font-weight: bold;
  outline: none;
}
@media only screen and (max-device-width: 480px) {
  .drop--lead--box{ 
    position: fixed !important;
    bottom: 44px !important;
    width: 95% !important;
    height: 510px !important;
    border-radius: 8px !important;
    background-color: #fff !important;
    box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.25) !important;
    z-index: 100 !important;
    transition: all 0.3s !important;
    right: 0px !important;
    left: 11px !important;
  }
}
.drop--lead--box{ 
  position: fixed;
  right: 20px;
  bottom: 60px;
  width: 450px;
  height: 510px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.25);
  z-index: 100;
  transition: all 0.3s;
}

.input-background{
  background-color: #F5F5F5;
  border:white;
}
.img-center{
  margin: auto auto;
  display: block;
}
.font-collor1{
  color: #7F7F7F;
}
.text-align-center{
  text-align: center;
}
.input--error{
  border: 1px solid red !important;
}
.telClass { display: none; }
.callToClass { display: block; } 
.callToClass:hover{
  color: #007bff !important;
}
.spanLink-blue{
  font-weight: bold;
  font-size: 1.2rem;
  cursor: pointer;
  color: #089CC9;
  text-decoration: none;
  background-color: transparent;
  text-decoration: underline;
}
.spanLink-pink{
  cursor: pointer;
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
.spanLink-pink:hover{
  color: var(--mainColor) !important;;
  text-decoration: underline;
}
.underline-pink{
  color: var(--mainColor) !important;;
  text-decoration: underline;
}

@media only screen and (max-device-width: 480px) {
    .telClass { display: block; }
    .callToClass { display: none; }
}

@media only screen and (max-device-width: 1024px) {
  .telClass { display: block; }
  .callToClass { display: none; }
  .mg--l {
    margin-left: 5px ;
  }
}

@media only screen and (max-device-width: 480px) {
  .drop--lead--ico{ 
    position: absolute !important;
    bottom: 85px !important;
    width: 60px !important;
    right: -6px !important;
  }
  .drop--lead--call{
    top: 3px !important;
    left: 2px !important;
  }
}
.drop--lead--ico{ 
  position: absolute ;
  bottom: 250px ;
  width: 60px ;
  right: 10px ;
  margin: 20px ;
}

.drop--lead--call{
  top: 3px;
  left: 2px;
}

/*css detail page*/

@media only screen and (max-device-width: 480px) {
  .detail--header--text {
    line-height: 1.3 !important;
    font-size: 24px !important;
  }

  .detail--title--text {
    font-size: 20px !important;
  }

  .detail--subheader--text {
    font-size: 18px !important;
  }

  .detail--subtitle--text {
    font-size: 16px !important;
  }

  .detail--subdetail--title--text {
    font-size: 14px !important;
    color: #7F7F7F !important;
  }

  .btn--detail--header--text {
    font-size: 18px !important;
  }
  
  .btndl--detail--header--text {
    font-size: 18px !important;
    padding: .475rem .75rem;
  }

  .detailBtnRadius, .detailBtnRadius:hover, .detailBtnRadius:focus, .detailBtnRadius:not(:disabled):not(.disabled):active{
    width:250 !important;
  }
  
  .dldetailBtnRadius, .dldetailBtnRadius:hover, .dldetailBtnRadius:focus, .dldetailBtnRadius:not(:disabled):not(.disabled):active{
    width: 250px;
}
}

@media only screen and (max-device-width: 1024px) {
  .detail--header--text {
    line-height: 1.3;
    font-size: 24px;
  }
  
  .detail--title--text {
    font-size: 18px;
  }
  
  .detail--subdetail--title--text {
    font-size: 14px;
    color: #7F7F7F;
  }

  .detail--subtitle--text {
    font-size: 16px;
  }

  .detail--subheader--text {
    font-size: 20px;
    
  }
}

.font-weight-demi-bold{
  font-weight: 600;
}

.font-weight-extra-bold{
  font-weight: 800;
}

.font-weight-demi-bold{
  font-weight: 600;
}

.font-weight-extra-bold{
  font-weight: 800;
}

.detail--header--text {
  line-height: 2;
  font-size: 32px;
}

.detail--subheader--text {
  font-size: 20px;
}

.detail--title--text {
  font-size: 24px;
}

.detail--subdetail--title--text {
  font-size: 16px;
  color: #7F7F7F;
}

.detail--subtitle--text {
  font-size: 18px;
}

.detail--img--size {
  width: 60px;
  height: 60px;
}

.btn--detail--header--text {
  margin-left: 10px;
  font-weight: 900;
  font-size: 24px
}

.btndl--detail--header--text {
  font-size: 18px;
  padding: .475rem .75rem;
}
.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 15px;
  
}
  .titleContainer {
    position: relative;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .img {
    width: 100%;
  }
  .img--mobile {
    display: none;
  }
  @media (max-width: 576px) {
    .img--mobile {
      display: block !important;
    }
  }
  @media (max-width: 576px) {
    .img--desktop {
      display: none !important;
    }
  }
  .featuresWrapper .featuresContainer, .promotionContainer, .disclaimerContainer, .landingRemarkContainer {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .detailBtnRadius, .detailBtnRadius:hover, .detailBtnRadius:focus, .detailBtnRadius:not(:disabled):not(.disabled):active{
    color: #fff !important;
    height: 56px;
    border-radius: 36px;
    background-color: var(--mainColor);
    border: 0;
    padding: 10px 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    box-shadow: none;
  }
  .dldetailBtnRadius, .dldetailBtnRadius:hover, .dldetailBtnRadius:focus, .dldetailBtnRadius:not(:disabled):not(.disabled):active{
    color: #fff;
    background-color: var(--white);
    border-radius: 50px;
    border: 2px solid;
    border-color: var(--mainColor);
    width: 300px;
    max-width: 100%;
    padding: .475rem .75rem;
  }
  .fixed {
    right: 0;
    position: fixed;
    z-index: 1;
    padding: 20px 0;
    background-color: rgba(255, 255, 255, 0.7);
    height: auto;
    width: calc(100% + 30px);
    left: 0;
    bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
    backdrop-filter: blur(10px);
  }
  @media (max-width: 1024px) {
    .fixed {
      padding: 20px 0 5px !important;
    }
  }
  @media (max-width: 480px) {
    .fixed {
      width: 100% !important;
      padding: 17.5px !important;
    }
  }
  .fixed-cookie {
    right: 0;
    position: fixed;
    z-index: 1;
    padding: 20px 0;
    background-color: rgba(255, 255, 255, 0.7);
    height: auto;
    width: calc(100% + 30px);
    left: 0;
    bottom: 153px;
    margin-top: 0;
    margin-bottom: 0;
    backdrop-filter: blur(10px);
  }
  @media (max-width: 1024px) {
    .fixed-cookie {
      padding: 20px 0 5px !important;
      bottom: 180px !important;
    }
  }
  @media (max-width: 480px) {
    .fixed-cookie {
      width: 100% !important;
      padding: 17.5px !important;
      bottom: 231px !important;
    }
  }
  .setFullImgAndTable table {
    width: 100% !important;
    border-collapse: collapse;
 }
  .setFullImgAndTable table th {
    height: 50px;
    border: 1px solid #dddee0;
 }
  .setFullImgAndTable table td {
    height: 50px;
    padding: 5px;
    border: 1px solid #dddee0;
 }
  .setFullImgAndTable table p {
    margin: auto;
 }
  .buttonImg {
    width: 27.5px;
}
@media (max-width: 480px) {
  .buttonImg {
    width: 26px!important;
  }
}
@media (max-width: 390px) {
  #stepSection ol li span {
    width: 57px;
    word-wrap: break-word;
    white-space: unset !important;
    text-align: center
  }
  #stepSection div.text--min {
    margin-bottom: 1.5rem !important;
  }
}

b, strong {
  font-weight: bold !important;
}
.ImRadioChecked--Custom .MuiFormControlLabel-label{
  margin-top: 5px;
}
.text--shutdown span{
  font-family: customFont !important;
}
@media (max-width: 576px) {
  .text--shutdown span{
    font-family: customFont !important;
    font-size: 16px !important;
  }
  .text--shutdown p{
    margin: 0 !important;
  }
}
.MuiInput-underline.Mui-error:after{
  border-bottom-color: #f44336 !important;
}
.Mui-Success .MuiInput-underline:after{
  border-bottom-color: #218838 !important;
  transform: scaleX(1) !important;
}
.components.hero-banner .carousel .carousel-indicators li {
  width: 7px;
  height: 7px;
  border-radius: 100%;
  margin: 0 10px;
  opacity: 1;
  background-color: #d7d7d7;
}
.components.hero-banner .carousel .carousel-indicators li.active {
  background-color: var(--mainColor);
}

@media (max-width: 550px) {
  #stepSection ol li span{
    white-space:normal !important;
    width:56px !important;
    text-align:center !important;
    margin-bottom:1.5rem !important;
  }
  #stepSection div.text--min {
    margin-bottom: 1.5rem !important;
  }
}

@media (max-width: 300px) {
  #stepSection div.text--min {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  #stepSection div.text--min>div:first-child {
    max-width: 95% !important;
  }
}


.QuestionCrsBox{
  border: 1px solid var(--mainColor) ;
  padding: 16px 20px;
  border-radius: 6px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.sizeCrs{
  width: 100%;
}
.checkboxCrs{
  display: flex;
  align-items: center;
}
.checkboxFont{
  margin-top: 12px;
  margin-left: 10px;
  font-family: kalatexa;
}

select:required:invalid {
  font-family: kalatexa;
  color: gray;
}
option[value=""][disabled] {
  display: none;
}
option {
  color: black;
}
.sizecheckBox {
  width: 16.472px;
  height: 16.472px;
}
.CRSQ002 .MuiInputLabel-animated{
color: #000;
}
.CRSQ004 .MuiInputLabel-animated{
  color: #000;
}
.CRSQ005 .MuiInputLabel-animated{
  color: #000;
}
.CRSQ002 .MuiFormLabel-root{
  font-size: 17px;
}
.CRSQ004 .MuiFormLabel-root{
  font-size: 17px;
}
.CRSQ005 .MuiFormLabel-root{
  font-size: 17px
}

@media (max-width: 590px) {
  .CRSQ002 .MuiFormLabel-root{
    font-size: 15px !important;
    display:flex;
    white-space:nowrap;
  }
  .CRSQ002 input{
    text-overflow: ellipsis;
  }
  .CRSQ002 .MuiInput-root input::placeholder{
    font-size: 15px !important;
  }
  #mui-component-select-CRSQ003{
    font-size: 15px !important;
  }
  #mui-component-select-CRSQ003 input::placeholder{
    font-size: 10px !important;
  }
  #mui-component-select-CRSQ004{
    font-size: 15px !important;
  }
  #mui-component-select-CRSQ004 input::placeholder{
    font-size: 10px !important;
  }
  .CRSQ004 .MuiFormLabel-root{
    font-size: 15px !important;
    display:flex;
    white-space:nowrap;
    text-overflow: ellipsis;
  }
  .CRSQ005 .MuiFormLabel-root{
    font-size: 15px !important;
    display:flex;
    white-space:nowrap;
  }
  .CRSQ005 .MuiInput-root input::placeholder{
    font-size: 15px !important;
    text-overflow: ellipsis;
  }
  .CRSQ005 .MuiInput-root input{
    font-size: 15px !important;
    text-overflow: ellipsis;
    width: 170px !important;
  }
  input .MuiInput-root input::placeholder{
    font-size: 15px !important;
  }
  .check_boxCrs{
    padding-left: 30px;
    font-size: 15px;
  }
  .CRSQ007 .MuiInput-root input{
    text-overflow: ellipsis; 
  }
  .CRSQ007 input::placeholder{
    font-size:15px !important
  }
}
.text-placeholder-conditions{
  color:#bdbdbd !important;
}
.MuiInput-underline.Mui-disabled:before {
  border-bottom-style: double !important;
}

.text-placeholder-conditions{
  color:#bdbdbd !important;
}
.MuiInput-underline.Mui-disabled:before {
  border-bottom-style: double !important;}

.detailJobBox{
  height: 48.569px;
  bottom: 25px;
}
.labelJobBox{
  position: relative !important;
}
.font-disable{
  color: rgba(0, 0, 0, 0.38) ;
}

.job ,.occupationCode{
  transform: translate(0px, 25px) scale(0.75) !important;
}

.MuiAutocomplete-endAdornment .MuiSvgIcon-root{
  background-image:  url("./images/Vector.svg") !important;
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 20px;
  color: transparent;
}
.MuiAutocomplete-popupIndicatorOpen{
  transform: unset !important;
}
.textTitleKplus{
  font-size: 18px;
}
.logoKplus{
  margin-top: 24px;
  width: 100px;
  height: 100px;
}
.textLogoKplus {
  background: rgb(230, 0, 126);
  width: 30px;
  height: 30px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: rgb(255, 255, 255);
  font-size: 14px;
}
.textKplusDesc{
  margin-top: 16px;
  font-size: 14px;
}
.textKplus{
  font-size: 16px;
}
@media (min-width: 768px){
  .textTitleKplus{
    font-size: 36px ;
  }
  .logoKplus{
    width: 180px;
    height: 180px;
  }
  .textLogoKplus {
    width: 48px;
    height: 48px;
    font-size: 22px;
  }
  .textKplusDesc{
    font-size: 24px;
  }
  .textKplus{
    font-size: 24px;
  }
}
.root1 {
  width: 221;
  font-size: 13;
}

.button1 {
  font-size: 13;
  width: "100%";
  text-align: "left";
  padding-bottom: 8;
  color: #586069;
  font-weight: 600;
  &:hover,&:focus {
    color: #0366d6
  }
  &span{
    width: "100%"
  }
  &svg{
    width: 16;
    height: 16;
  }
}

.tag1 {
  margin-top: 3;
  height: 20;
  padding: .15em 4px;
  font-weight: 600;
  line-height: 15px;
  border-radius: 2;
}
.popper1 {
  border: 1px solid rgba(27,31,35,.15);
  box-shadow: 0 3px 12px rgba(27,31,35,.15);
  border-radius: 3;
  width: 300;
  z-Index: 1;
  font-Size: 13;
  color: #586069;
  background-color: #f6f8fa;
}
.header1 {
  border-bottom: 1px solid #e1e4e8;
  padding: 8px 10px;
  font-Weight: 600
}
.inputBase1 {
  padding: 10px;
  width: 100%;
  border-bottom: 1px solid #dfe2e5
}
.inputBase1 > input{
  border-radius: 4px;
  background-color: white;
  padding: 8px;
  border-color:#ce3a3a ;
  box-shadow: #1f816b;
  border:1px solid #ced4da;
  font-size: 14;
}
.inputBase1 > input:focus{
  box-shadow: #008ECF;
  border-color: #28a745;
}

.paper1 {
  box-shadow: none;
  margin: 0;
  color: #586069;
  font-size: 13
}
.option1 {
  min-height: auto;
  align-items: flex-start;
  padding: 8,
  /* '&[aria-selected="true"]'{
    backgroundColor: "transparent"
  },
  '&[data-focus="true"]': {
    backgroundColor: theme.palette.action.hover
  } */
}
.popperDisablePortal1 {
  position: relative;
}
.iconSelected {
  width: 17;
  height: 17;
  margin-right: 5;
  margin-left: -2;
}
.color1{
  width: 14;
  height: 14;
  flex-shrink: 0;
  border-radius: 3;
  margin-right: 8;
  margin-top: 2;
}
.text1{
  flex-grow: 1
}
.close1{
  opacity: 0.6;
  width: 18;
  height: 18;
}
/* 
.MuiAutocomplete-popper{
 width: 100% !important;
} */

/* .iconDropdown{
  background-image:  url("./images/Vector.svg") !important;
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 20px;
  color: transparent;
}

#mui-91589{
  background-image:  url("./images/Vector.svg") !important;
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 20px;
  color: transparent;
}
.test1{
  background-color: red;
} */
/* .test1{
  transform: translate3d(65px, 232px, 0px) !important;
} */

.rootMAutocompleteInList{
  position: relative;
}
.dropdownMAutocompleteInList{
  position: absolute;
  top: 13px;
  right: 0;
  left: 0;
  z-index: 9999;
  border: 1px solid #DDDEE0;
  border-radius: 6px;
  padding: 15px 15px 0px 15px;
  background-color: #fff;
  box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
}
.dropdownMAutocompleteInList .menuList{
  max-height: 200px;
  margin-top: 16px;
  overflow-y: scroll;
}
.dropdownMAutocompleteInList .menuList .list{
  padding-top: 6px;
  padding-bottom: 6px;
  background-color: #fff;
  color: #383838;
  font-size: 1rem;
  /* font-weight: bold; */
}
.dropdownMAutocompleteInList .menuList .list:hover{
  background-color: #f5f5f5;
}
.dropdownMAutocompleteInList .menuList .list.active{
  background-color: #DDDEE0;
}
.qrCodeKplus { 
  height: auto;
  max-width: 25%;
  width: 25%;
}
@media (max-width: 576px){
  .qrCodeKplus { 
    height: auto;
    max-width: 50%;
    width: 50%;
  }
  .textKPlus h3 {
    font-size: 20px;
  }
}
@media (max-width:551px){
  #insuranceTrackLabel{
    margin-top: -5px;
  }
  #mui-component-select-insuranceTrack1{
    margin-top: 7px
  }
}
.appDeepLinkedAWThankYouPageImage:hover{
  cursor: pointer;
}
.fixedContinue {
  right: 0;
  position: fixed;
  z-index: 1;
  padding: 20px 0;
  background-color: rgba(255, 255, 255, 0.7);
  height: auto;
  width: calc(100%);
  left: 0;
  bottom: 0;
  margin-top: 0;
  margin-bottom: 0;
  backdrop-filter: blur(10px);
}
@media (max-width: 576px) {
  .fixedContinue {
    width: 100% !important;
    padding: 17.5px !important;
  }
}
.loader-kplus {
  border: 5px solid #D7D7D7;
  border-radius: 50%;
  border-top: 5px solid var(--mainColor);
  border-right: 5px solid var(--mainColor);
  border-bottom: 5px solid var(--mainColor);
  width: 48px;
  height: 48px;
  -webkit-animation: spinkplus 2s linear infinite; /* Safari */
  animation: spinkplus 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spinkplus {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spinkplus {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.historySms {
  padding: 20px 0;
}
.historySmsPage {
  padding: 40px 0;
  gap: 20px;
  .title {
    display: flex;
    @media (max-width: 576px) {
      align-items: center;
      justify-content: center;
    }
    .titleText {
      color: #383838;
      font-size: 34px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      @media (max-width: 576px) {
        text-align: center;
        font-size: 20px;
      }
      img {
        width: 38px;
        height: 38px;
        @media (max-width: 576px) {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
  .contentList {
    margin: 0 -8px;
    align-items: stretch;
    @media (max-width: 576px) {
      margin: 0 -16px;
    }
    .contentCard {
      padding: 0;
      padding-left: 8px;
      padding-right: 8px;
      margin-top: 32px;
      @media (max-width: 576px) {
        padding-left: 16px;
        padding-right: 16px;
        margin-top: 16px;
      }
      @media (min-width: 576px) and (max-width: 1199px) {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
  }
  .emptyData {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 40px 0;
    // gap: 20px;
    .emptyDataTitle {
      color: #383838;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding-top: 20px;
      @media (min-width: 576px) {
        font-size: 18px;
      }
    }
    .emptyDataSubTitle {
      color: #383838;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding-top: 12px;
      @media (min-width: 576px) {
        font-size: 16px;
      }
    }
  }
}
.btn {
  justify-content: center;
  display: flex;
  margin: 34px auto 0;
  //   @media (max-width: 576px) {
  //     display: none;
  //   }
}

.footerButtom {
  // height: 60px;
  padding: 10px;
  box-shadow: 0 -2px 15px rgba(0, 0, 0, 0.05);
  position: fixed;
  left: 0px;
  bottom: 0px;
  background: #ffffff;
  width: 100%;
  z-index: 19;
  font-weight: 800;
  font-size: 17px;
  line-height: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (min-width: 576px) {
    display: none;
  }
}

.contentBox {
  // min-width: 377px;
  height: 444px;
  margin-bottom: 30px;
  background-color: #fff;
  -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  position: relative;

  .imageContainer {
    // height: 220px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    overflow: hidden;
    .image {
      width: 100%;
      // height: 100%;
      // object-fit: cover;
      &.mobile {
        display: none;
        @media (max-width: 576px) {
          display: block;
        }
      }
      &.desktop {
        @media (max-width: 576px) {
          display: none;
        }
      }
    }
  }

  .detailContainer {
    font-size: 18px;
    position: relative;
    padding: 20px;
    text-overflow: ellipsis;
    %generalBadge {
      border: 2px solid #fff;
      color: #fff;
      padding: 2px 15px;
      border-radius: 15px;
      position: absolute;
      top: -18px;
      left: 20px;
      font-weight: 600;
    }

    .typeBadge {
      @extend %generalBadge;
      // background-color: #68c079;
    }
    .title {
      color: #353535;
      width: 100%;
      font-weight: bold;
      margin-bottom: 10px;
    }

    .desc {
      color: #5b6670;
      display: -webkit-box;

      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1.4;
      &.titleDesc {
        margin-bottom: 10px;
      }
    }
  }

  .actionsContainer {
    position: absolute;
    width: 100%;
    height: 50px;
    bottom: 0;
    color: #383838;
    font-weight: bold;
    .button {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      &.left {
        background-color: #fff;

        border-bottom-left-radius: 10px;
        border-top: 1px solid #dddee0;
        border-right: 1px solid #dddee0;
      }
      &.right {
        color: #ed028c;
        background-color: #fff;
        border-bottom-right-radius: 10px;
        border-top: 1px solid #dddee0;
      }
    }
  }
}

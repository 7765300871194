.modal {
  background-color: #fff;
  border-radius: 8px;
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  // width: 80%;
  height: 100%;
  padding: 30px 60px 50px 60px;
  // min-height: 606px;
  overflow: hidden;
  z-index: 100;
  box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.1);
  // @media (max-width: 991px) {
  //   width: 90%;
  //   height: 90%;
  // }
  @media (max-width: 576px) {
    width: 100%;
    // height: calc(110vh - 1rem);
    height: calc(100vh - 1rem);
    padding: 20px;
  }
  .modalHeader {
    width: 100%;
    text-align: right;
    @media (max-width: 576px) {
      width: auto;
      position: absolute;
      right: 20px;
      top: 20px;
    }
    + .modalTitle,
    + .bigModalTitle {
      @media (max-width: 576px) {
        margin-top: 12px;
        width: 85%;
      }
    }
  }
  .modalTitleMatchYourNeed {
    font-size: 24px;
    font-weight: bold;
    color: #ed028e;
    margin-top: 22px;
    margin-bottom: 0;
    text-align: center;
    @media (max-width: 576px) {
      font-size: 24px;
      margin-top: 0;
    }
  }
  .modalTitle {
    font-size: 24px;
    font-weight: bold;
    color: #ed028e;
    // margin-top: 22px;
    margin-bottom: 0;
    @media (max-width: 576px) {
      font-size: 18px;
      margin-top: 0;
    }
  }
  .bigModalTitle {
    font-size: 32px;
    font-weight: bold;
    color: #ed028e;
    // margin-top: 22px;
    margin-bottom: 0;
    @media (max-width: 576px) {
      font-size: 18px;
      margin-top: 0;
    }
  }
  .modalSubTitle {
    font-size: 18px;
    font-weight: normal;
    color: #7f7f7f;
    padding-top: 0;
    padding-bottom: 0;
    @media (max-width: 576px) {
      font-size: 16px;
    }
  }
  .modalContent {
    overflow: hidden;
    overflow-y: auto;
    min-height: 200px;
    height: calc(100% - 185px);
    font-size: 16px;
    font-weight: 600;
    margin-top: 15px;
    @media (max-width: 991px) {
      height: 40vh;
    }

    @media (max-height: 896px) {
      height: 57vh;
    }
    @media (max-height: 736px) {
      height: 60vh;
    }
    @media (max-height: 667px) {
      height: 52vh;
    }
    @media (max-height: 576px) {
      height: 55vh;
    }
  }
}
.modalOverlay {
  // background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}

.modalSize {
  width: 400px;
}

.mainCarousel {
    .imageContainer {
      // height: 455px;
      // display: flex;
      // justify-content: center;
      .image {
        width: 100%;
        // height: auto;
        object-fit: cover;
        &.mobile {
          display: none;
          // height: 187px;
          @media (max-width: 576px) {
            display: block;
          }
        }
        &.desktop {
          height: 554px;
          @media (max-width: 576px) {
            display: none;
          }
        }
      }
    }
    .captionbanner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      right: auto;
      bottom: auto;
      text-align: left;
      z-index: 1;
      @media (max-width: 576px) {
        top: auto;
        bottom: 7%;
        transform: translateX(-50%);
        text-align: center;
      }
      h1 {
        font-size: 50px;
        font-weight: 900;
        color: #e6007e;
        margin-bottom: 17px;
        line-height: 1.1;
        span {
          font-size: 36px;
          font-weight: 900;
          color: #2d2e83;
          margin-bottom: 0;
          max-width: none;
          @media (max-width: 991px) {
            font-size: 24px;
            font-weight: bold;
          }
        }
        @media (max-width: 991px) {
          font-size: 36px;
          font-weight: bold;
          margin-bottom: 0;
          line-height: 1.4;
        }
      }
      h2 {
        font-size: 50px;
        font-weight: 800;
        color: #e6007e;
        @media (max-width: 991px) {
          font-size: 24px;
          font-weight: bold;
        }
      }
      h3 {
        font-size: 24px;
        font-weight: 800;
        color: #e6007e;
        @media (max-width: 991px) {
          font-size: 20px;
          font-weight: bold;
        }
      }
      h4 {
        color: #e6007e;
        font-size: 24px;
        font-weight: 900;
      }
      p {
        font-size: 24px;
        font-weight: 700;
        @media (max-width: 991px) {
          font-size: 18px;
        }
      }
      span {
        font-size: 20px;
        font-weight: bold;
        color: #2d2e83;
        display: block;
        margin-bottom: 10px;
        @media (max-width: 991px) {
          font-size: 14px;
        }
      }
      h1,
      h2,
      h3,
      h4,
      p,
      span {
        max-width: 50%;
        @media (max-width: 768px) {
          max-width: 70%;
        }
        @media (max-width: 576px) {
          max-width: none;
        }
      }
    }
  }
  
.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: auto;
  height: 100%;
  border-radius: 8px;
  border: 1px solid #383838;
  &.active {
    border: 1px solid #e6007e;
    @media (max-width: 576px) {
      &.inActive {
        // border: 1px solid #c3c3c3;
      }
    }
  }
  @media (min-width: 576px) {
    &.inActive {
      border: 1px solid #c3c3c3;
    }
  }
  .hover {
    display: none;
  }
  &:hover {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    .iconDesktop {
      background-color: #ffeff6;
      .normal {
        display: none;
      }
      .hover {
        display: unset;
      }
    }
  }
}
.cardHeader {
  // height: 66px;
  border-radius: 8px;
  padding: 14px;
  display: flex;
  justify-content: space-between;
  @media (min-width: 576px) {
    height: calc(100% - 106px);
  }
  &.active {
    background: #ffeff6;
    @media (max-width: 576px) {
      &.inActive {
        background: #f2f4f8;
      }
    }
  }
  @media (min-width: 576px) {
    &.inActive {
      background: #f2f4f8;
    }
  }
  .productPlanAndChannel {
    .productAndPlan{
      display: flex;
      flex-direction: column;
    }
    .productName {
      color: #e6007e;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 17px; /* 141.667% */
      @media (max-width: 370px) {
        font-size: 14px;
      }
    }
    .plan {
      padding: 5px 0 0 0;
      color: #008ecf;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 17px;
      @media (max-width: 370px) {
        font-size: 14px;
      }
    }
    .channel {
      color: var(--Neutral-Grey-60, #acaeb2);
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 17px;
    }
  }
  .statusAndIcon {
    min-width: 100px;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-content: center;
    align-items: flex-end;
    gap: 1px;
    .icon {
      .iconMobile {
        width: 24px;
        height: 24px;
        transition: 0.4s;
        &.active {
          transform: rotate(180deg);
        }
        @media (min-width: 577px) {
          display: none;
        }
      }
      .iconDesktop {
        width: 19px;
        height: 19px;
        margin: 2.4px;
        border-radius: 60%;
        border: 2px solid #e6007e;
        text-align: center;
        img {
          vertical-align: top;
          margin-top: 4px;
          width: 13px;
        }
        &.active {
          background-color: #e6007e;
        }
        @media (max-width: 576px) {
          display: none;
        }
      }
    }
    .status {
      text-align: right;
      padding: 4px 12px;
      border-radius: 100px;
      background: linear-gradient(270deg, #ff157d -19.38%, #f6adcc 101.28%);
      color: #fff;
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: 13px;
      &.expire {
        background: #c3c3c3;
      }
      &.succeed {
        background: #16a851;
      }
    }
  }
}
.cardDetail {
  max-height: 0;
  overflow: hidden;
  transition: 0.4s;
  @media (min-width: 576px) {
    height: 106px;
  }
  &.active {
    // height: auto;
    max-height: 1000px;
  }
  @media (min-width: 577px) {
    max-height: 1000px;
  }
  .detailList {
    padding: 11px 14px;
    gap: 4px;
  }
  .detail {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    .title {
      color: #8e9094;
      @media (max-width: 300px) {
        min-width: 73px;
      }
    }
    .date {
      text-align: right;
      color: #383838;
    }
    .lastStep {
      text-align: right;
      color: #008ecf;
    }
    .status {
      text-align: right;
      color: #383838;
    }
    .premium {
      text-align: right;
      color: #383838;
      .desktop{
        display: none;
        @media (min-width: 301px){
          display: inline-block;
        }
      }
      .mobile{
        display: none;
        @media (max-width: 300px) {
          display: inline-block;
        }
      }
      .lineThrough {
        padding-left: 4px;
        text-decoration-line: line-through;
        text-decoration-thickness: 2px;
        text-decoration-color: rgba(1, 140, 205, 0.9);
        color: #8e9094;
      }
    }
  }
}

  .title {
    font-size: 32px;
    font-weight: bold;
    line-height: 1.4;
    margin-bottom: 0;
    letter-spacing: 0;
    @media (max-width: 576px) {
      font-size: 18px;
    }
  }
  .desc {
    font-size: 18px;
    color: #7f7f7f;
    @media (max-width: 576px) {
      font-size: 16px;
    }
  }
  .subtitle {
    font-size: 24px;
    font-weight: bold;
    color: var(--mainColor);
    @media (max-width: 576px) {
      font-size: 16px;
    }
  }
  .label {
    margin-bottom: 18px;
    font-size: 20px;
    font-weight: bold;
    color: #383838;
    @media (max-width: 576px) {
      margin-bottom: 8px;
      font-size: 14px;
    }
  }
  .images {
    border-radius: 8px;
    border: 1px solid #c3c3c3;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px;
    @media (max-width: 576px) {
      padding: 30.5px;
    }
    img {
      width: 256px;
      height: auto;
      object-fit: contain;
      @media (max-width: 576px) {
        width: 160px;
      }
    }
  }
  .images2 {
    border-radius: 8px;
    border: 1px solid #c3c3c3;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px;
    @media (max-width: 991px) {
      margin-bottom: 22px;
    }
    @media (max-width: 576px) {
      padding: 30.5px;
    }
    img {
      width: 256px;
      height: auto;
      object-fit: contain;
      @media (max-width: 576px) {
        width: 160px;
      }
    }
  }
  .upload {
    > div {
      border: 1px dashed #089cc9;
      border-radius: 8px;
      min-height: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      outline: none;
      @media (max-width: 991px) {
        margin-bottom: 22px;
      }
      > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        p {
          color: #089cc9;
          font-family: "customFont";
          font-weight: bold;
          font-size: 18px;
          order: 2;
          width: 100%;
          padding: 0 10px;
          @media (max-width: 576px) {
            font-size: 16px;
            margin-top: 5px;
            margin-bottom: 0;
          }
        }
        svg {
          color: #089cc9;
          order: 1;
        }
      }
    }
    span {
      font-family: "kalatexa";
      font-weight: bold;
      font-size: 18px;
      color: #089cc9;
      @media (max-width: 576px) {
        font-size: 16px;
      }
    }
  }
  
  .birth_cert {
    margin-bottom: 22px;
    .upload {
      > div {
        height: 395px;
        @media (max-width: 576px) {
          height: 160px;
        }
      }
    }
    .images {
      height: 395px;
      @media (max-width: 576px) {
        height: auto;
      }
    }
  }
  .id_card,
  .payer_id_card {
    margin-bottom: 22px;
    .upload {
      > div {
        height: 212px;
        @media (max-width: 576px) {
          height: 164px;
        }
      }
    }
    .images {
      height: 200px;
      @media (max-width: 576px) {
        height: auto;
      }
    }
  }
  .face_shape {
    margin-bottom: 22px;
    .upload {
      > div {
        height: 263px;
        @media (max-width: 576px) {
          height: 196px;
        }
      }
    }
    .images {
      height: 200px;
      @media (max-width: 576px) {
        height: auto;
      }
      img {
        width: 196px;
        height: auto;
        object-fit: contain;
        @media (max-width: 576px) {
          width: 100px;
        }
      }
    }
  }
  .book_bank {
    margin-bottom: 22px;
    .upload {
      > div {
        height: 310px;
        @media (max-width: 576px) {
          height: 160px;
        }
      }
    }
    .images {
      height: 310px;
      @media (max-width: 576px) {
        height: auto;
      }
    }
  }
  .linkModal {
    margin-bottom: 46px;
    font-size: 20px;
    font-weight: bold;
    @media (max-width: 576px) {
      font-size: 14px;
    }
    span {
      color: #e6007e;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .filterUpload{
    position: absolute;
    width: calc(100% - 30px);;
    z-index: 1;
    display: unset;
    cursor: pointer;
  }

  .previewUpload {
    position: absolute;
    width: calc(100% - 30px);;
    z-index: 1;
    display: unset;
    cursor: pointer;
    font-size: 18px;
    color: #7F7F7F;
    font-weight: bold;
    @media (max-width: 576px) {
      font-size: 16px;
    }
    u {
      margin-top: 113px;
      cursor: pointer;
      @media (max-width: 576px) {
        margin-top: 106px;
      }
    }
  }
  .previewModal {
    min-height: unset;
    max-width: unset;
    margin: 20px;
    // height: calc(100% - 40px);
  }
  .changUpload {
    border: none !important;
    position: absolute;
    width: 22%;
    height: 12% !important;
    left: 39%;
    bottom: 28px;
    z-index: 1;
    cursor: pointer;
    color: #7F7F7F;
    font-weight: bold;
    font-size: 18px;
    @media (max-width: 576px) {
      font-size: 16px;
      bottom: 14px;
    }
  }

    